import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContent = [
    
    {
        image: 'image-2',
        href:"https://course.usebegitrading.com/1616018575247",
        title: ' תוכנית הכשרה מסחר סווינג',
        text:`אלמד אתכם להשתמש, בכל הכלים וטכניקות המסחר בהם אני משתמש בהצלחה  למסחר והשקעה במניות וקרנות סל ETF, כולל הוראות הפעלה מדוייקות, לניתוח ובניית תיק מנצח שמתאים אישית לכל אחד ואחת מכם.
        תוכנית הכשרה הינה פרקטית וממוקדת למן הרגע הראשון.
        החומר הנלמד מבוסס על 20 שנות ניסיון פרקטי, במהלכן הכשרתי סוחרים מנוסים וחסרי ניסיון.
        זה המקום לציין שבכדי להצליח, נלמד אתכם ממה להימנע ומה לא לעשות, אותן טעויות נפוצות שנובעות מכשל פסיכולוגי והצלחה ריגעית.
        שם המשחק – תוחלת חיובית.`
    },
    {
        image: 'image-1',
        href:"https://daytrade.usebegitrading.com/1622394668848",
        title: 'תוכנית הכשרה למסחר יומי',
        text:`נלמד להשתמש, בכל הכלים וטכניקות המסחר בהם אני משתמש בהצלחה  למסחר יומי במניות וקרנות סל ETF, כולל הוראות הפעלה מדוייקות:        
        ,לבחור את המניות הנכונות
        למצוא את אסטרטגיית המסחר המתאימה,
        לנהל סיכונים כמו המקצוענים,
        להפסיק לפחד להרוויח ולהתגבר על הקושי הפסיכולוגי,
        תוכנית הכשרה הינה פרקטית וממוקדת למן הרגע הראשון.
        `
    },
    {
        image: 'image-3',
        href:"https://my.schooler.biz/s/36625/1634132062782",
        title: ' תוכניה הכשרה מסחר אופציות',
        text:`המסחר באופציות ונגזרים נחשב למתוחכם ביותר מכל הנכסים הפיננסיים, למעשה, אין שחקן משמעותי שלא עושה בו שימוש, בין אם מדובר במנהלי השקעות מוסדיים או קרנות גידור וחברות נוסטרו, שנחשבים לשחקנים האגרסיביים והריווחיים ביותר בשוק.
        הכשרה מכסה את מכלול האספקטים של אופציות כולל שילוב בין אופציות למניות ( ונכסים פיננסיים נוספים ),  מודלים לתמחור, שיקולי תנודתיות, אסטרטגיות בסיסיות ומתקדמות והיבטים הקשורים בניהול הסיכונים`
    },
    {
        image: 'image-3',
        href:"https://wa.me/972524416005",
        title: 'תוכנית ליווי אישי',
        text:`מסלול זה מתאים למי שזקוק לליווי והכונה פרטנית,בין אם מדובר במשקיעים/סוחרים מנוסים או בתחילת דרכם, כאלה המעוניינים לבצע קפיצת מדרגה או צריכים לקבל החלטות מהותיות בתחום`
    },
    // {
    //     image: 'image-3',
    //     category: 'Development',
    //     title: 'Getting tickets to the big show'
    // },
    // {
    //     image: 'image-4',
    //     category: 'Development',
    //     title: 'Getting tickets to the big show'
    // }
]
const boxStyle={
    border:'6px solid #1d1d24'
}
class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <h4 className="text-center mt--40"><a href={value.href}>{value.title}</a></h4>
                        <div className={`portfolio ${styevariation}`} style={boxStyle}  >
                        
                            {/* <div className="thumbnail-inner">
                                
                                <div className={`bg-blr-image ${value.text}`}></div>
                            </div> */}
                            <div  className="content">
                                <div className="inner" >
                               
                                    <p>{value.text}</p>
                                   
                                </div>
                            </div>
                            <div className="portfolio-button">
                                        <a className="rn-btn mt--40" href={value.href}>לפרטים לחצו כאן</a>
                                    </div>  
                            {/* <Link className="link-overlay" to={value.href}></Link> */}
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;