import React from 'react'
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactThree from "../elements/contact/ContactThree";
import PortfolioList from "../elements/portfolio/PortfolioList";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";

const SlideList = [
    {
        textPosition: 'text-right',
        category: 'ברוכים הבאים לעולם המסחר',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]
const PortfolioLanding = () => {
    
    return (
        <>
              <div className="privcy-page-inner ">
        <Helmet pageTitle="The way to Trade" />
        <div     className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                           <br></br>
                                           
                                        
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>

  
            <div className="container">
            <h3>מדיניות פרטיות</h3>
                <div className="privacy-page text-right span row">
                
            <ul className='privacy-page text-right span'>
            <li>מפעילי האתר שומרים לעצמם את הזכות לשנות את מדיניות הפרטיות מעת לעת. על מנת להישאר מעודכן, מומלץ לעיין במדיניות זו באופן קבוע.</li>
            <li>הכניסה והשימוש באתר מהווים הסכמה למדיניות פרטיות זו.</li>
<li>משתמשים המעוניינים ליצור קשר עם האתר יתבקשו למסור פרטים כגון: שם פרטי, שם משפחה, כתובת דוא"ל, מספר טלפון, פרטי ההודעה וייתכן כי יידרשו לספק פרטים נוספים.
                בעת יצירת הקשר, מפעילי האתר יוכלו לקבל מידע על כתובת ה-IP של המכשיר ממנו בוצעה הפנייה.</li>
<li>בהשארת פרטי הקשר, אתה מסכים לכך שמפעילי האתר יוכלו לפנות אליך בכל אמצעי תקשורת.</li>
<li>יתכן ותקבל למייל שלך הודעות המכילות עדכונים, תכנים, מאמרים, פרסומות, מסרים שיווקיים, קישורים או דפי נחיתה, וזאת על סמך הסכמתך המפורשת לקבל הודעות מסוג זה בעת מסירת כתובת הדוא"ל שלך לאתר.
                מפעילי האתר שומרים לעצמם את הזכות להחליט האם לשלוח הודעות דוא"ל למשתמשים מסוימים. באפשרותך להסיר את עצמך מרשימת התפוצה בכל עת דרך הקישור שמופיע במייל שנשלח אליך או על ידי יצירת קשר עם מפעילי האתר.</li>
            </ul>
                </div>
            </div>
        
        </div>
        <FooterTwo />
        </>
        
    )
    
}

export default PortfolioLanding;
