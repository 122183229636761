import React, { Component } from "react";

import ContactForm from "./ContactForm";
class ContactThree extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        };

        
     
    }
    onComplete=()=>{  
        // const sgMail= require('@sendgrid/mail')
        // const sgAPIkey=process.env.REACT_APP_SENDGRID 
        // sgMail.setApiKey(sgAPIkey)
        // console.log(sgMail)
        
        // debugger
        // const message={
        //     to:process.env.REACT_APP_SENDEMAILTO,
        //     from:this.state.rnEmail,
        //     subject:this.state.rnSubject,
        //     html:`<p>${this.state.rnName}</p><p>${this.state.rnMessage}</p>`
        // }
        

        // sgMail.send(message).then(x=>
        //     alert("Sdfdsf")
            
        // ).catch((error)=>{            
        //     console.error(error)
            
        //     alert(error)
        // })
        // debugger        
    
      
            // e.target.reset();
            // showresult(true);        
      } 
    render(){
        return(
            
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-right mb--50">
                                <h2 className="title">{this.props.contactTitle}</h2>
                                <p className="description">ניתן להשיג אותנו או על ידי שליחת מייל או בווטסאפ בטלפון<a href="https://wa.me/972524416005" target="_blank">  0524416005</a>  
                                    </p>
                                    
                            </div>
                            <ContactForm />
                            {/* <div className="form-wrapper"> 
                                <form onSubmit={this.onComplete}>
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                            placeholder="שם מלא *"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                            placeholder="אימייל *"
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e)=>{this.setState({rnSubject: e.target.value});}}
                                            placeholder="נושא ההודעה"
                                        />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                            placeholder="פה כותבים את ההודעה"
                                        />
                                    </label>
                                    <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="">שלח</button>
                                </form>
                            </div> */}
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                { <img src="/assets/images/logo/usebegilogo3.png" alt=""/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactThree;