import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";
import { FaSpotify } from "react-icons/fa";

const ServiceList = [
    // {
    //     icon: <FiCast />,
    //     title: 'ליווי אישי',
    //     description: 'ליווי אישי  '
    // },
    {
        icon: <FiLayers />,
        title: 'קבוצת VIP',
        description: 'קבוצת זו נוצרה בכדי ללמד, להעשיר ידע ולחלוק רעיונות בשוק ההון.בכל יום נעלה הקלטה יומית ונתייחס לנעשה בשווקים, בדגש על בורסות ארה"ב, כאשר גם משקיעים לטווח ארוך וגם סוחרי סווינג וסוחרים אגרסיביים יימצאו תכנים ורעיונות השקעה ומסחר רלוונטיים עבורם.בכדי לאפשר לחברי הקבוצה לשוחח אחד עם השני ומצד שני למנוע רעשי מיותרים, ישנן 2 קבוצות VIP, אחת "שקטה" בה רק מנהלי הקבוצה רשאים לכתוב ולשתף ואילו השניה, פתוחה לדיונים בין כל חבריה. דיסקליימר:  כל העושה שימוש בתכנים המועלים בקבוצה, עושה זאת על דעת עצמו ואחריותו הבלעדית.',
        href:"https://wa.me/972524416005"
    },
    {
        icon: <FaSpotify />,
        title: 'הקלטות יומיות',
        description: 'הקלטה יומית על המסחר שהיה ועל מה שמחכה ביום המסחר הנוכחי.',
        href:"https://open.spotify.com/show/43j3adxbmRSdQPH73jtpFd"
    }
    // { 
    //     icon: <FiMonitor />,
    //     title: 'Mobile App Development',
    //     description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    // },
    // {
    //     icon: <FiUsers />,
    //     title: 'Marketing & Reporting',
    //     description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    // },
    // { 
    //     icon: <FiMonitor />,
    //     title: 'Mobile App Development',
    //     description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    // }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                             <a href={val.href} target="_blank"> 
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                             </a> 
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
